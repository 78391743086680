<template>
  <div class="amap-page-container">
    <el-amap vid="amapDemo" :center="center" :zoom="zoom" :plugin="plugin" class="amap-demo">
      <el-amap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :icon="marker.icon"
        :vid="index"
      ></el-amap-marker>
    </el-amap>
    <div class="icon">
      <ul>
        <li>
          <img src="./imgs/hotel.png" alt />
          <p>酒店</p>
        </li>
        <li class="topBorder">
          <img src="./imgs/canying.png" alt />
          <p>饭店</p>
        </li>
        <li class="topBorder">
          <img src="./imgs/theGreatView.png" alt />
          <p>景点</p>
        </li>
        <li class="topBorder">
          <img src="./imgs/wholeView.png" alt />
          <p>大屏</p>
        </li>
        <li class="topBorder">
          <img src="./imgs/wc@2x.png" alt />
          <p>厕所</p>
        </li>
        <li class="topBorder">
          <img src="./imgs/sos@2x.png" alt />
          <p>SOS</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.amap-page-container {
  height: 100%;
  position: relative;
}

.icon {
  position: absolute;
  bottom: 0.293rem;
  right: 0.24rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0.027rem 0.107rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.107rem;
  border: 0.013rem solid rgba(238, 238, 238, 1);
  padding: 0.16rem;
}
.topBorder {
  border-top: 0.013rem solid #e2e1e1;
}
.icon ul li {
  list-style: none;
  font-size: 10px;
  margin-top: 5px;
}

.icon ul li img {
  width: 22.4px;
  height: 22.4px;
}

.icon ul li p {
  margin: 0;
}
</style>
<style>
.amap-icon img {
  width: 30px;
  height: 30px;
}
</style>
<script>
// NPM 方式
import VueAMap from 'vue-amap';
VueAMap.initAMapApiLoader({
  key: '09ba3397bed53916f7419b24ae4a9707',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
  uiVersion: '1.0.11', // 版本号
  v: '1.4.4'
});
import { AMapManager } from 'vue-amap';
export default {
  computed: {
    getZoom() {
      console.log(this.zoom);
      return this.zoom > 12;
    }
  },
  data: function() {
    let self = this;
    const center = [120.001362, 27.384924];

    return {
      zoom: 12,
      center,
      lng: 0,
      lat: 0,
      loaded: false,
      plugin: [
        {
          pName: 'Geolocation',
          events: {
            init(o) {
              // o 是高德地图定位插件实例
              // o.getCurrentPosition((status, result) => {
              // 	if (result && result.position) {
              // 		self.lng = result.position.lng;
              // 		self.lat = result.position.lat;
              // 		self.center = [self.lng, self.lat];
              // 		self.loaded = true;
              // 		self.$nextTick();
              // 	}
              // });
            }
          }
        }
      ],
      markers: [
        // 酒店
        {
          position: [120.001362, 27.384924],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.050512, 27.390532],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [119.99787, 27.472884],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.001388, 27.470744],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [119.996289, 27.471093],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.001486, 27.472212],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.001365, 27.470847],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [119.998726, 27.471675],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [119.507854, 29.793162],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.088121, 27.394398],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.087357, 27.384914],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.072239, 27.408188],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [120.05234, 27.391285],
          icon: require('./imgs/icon1@2x.png')
        },
        {
          position: [119.99714, 27.470214],
          icon: require('./imgs/icon3@2x.png')
        },
        // 餐饮
        {
          position: [119.996148, 27.46935],
          icon: require('./imgs/icon3@2x.png')
        },
        {
          position: [120.002104, 27.468241],
          icon: require('./imgs/icon3@2x.png')
        },
        {
          position: [119.941019, 27.621713],
          icon: require('./imgs/icon2@2x.png')
        },
        {
          position: [120.066783, 27.375054],
          icon: require('./imgs/icon2@2x.png')
        }
        // 景点
      ]
    };
  },
	created() {
		// CDN 方式
		let amapManager = new VueAMap.AMapManager();
		// let self = this;
		// let markers = [];
		// let index = 0;

		// let basePosition = [121.59996, 31.197646];
		// let num = 10;

		// for (let i = 0 ; i < num ; i++) {
		// markers.push({
		// 	position: [basePosition[0], basePosition[1] + i * 0.03],
		// 	icon:require('../../assets/img/wc.png')
		// });
		// }
		// this.markers = markers;
	}
};
</script>
